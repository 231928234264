var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.post)?_c('main',{staticClass:"single--product main--no-hero main-theme--white"},[_c('Section',{attrs:{"layout":{
      section_wrapper: 'xl',
      section_wrapper_boxed: true,
      section_layout: 'default',
      section_column: '1',
      section_spacer: 'empty',
    }}},[(_vm.post.gds_featured_image)?_c('Column',{staticClass:"image-column",attrs:{"size":{ default: 12, m: 5 }}},[_c('Media',{attrs:{"data":{
          type: 'image',
          image: _vm.post.gds_featured_image,
          ratio: false,
          theme: 'single-product',
        }}})],1):_vm._e(),_c('Column',{attrs:{"tag":"aside","size":{ default: 12, m: 7 }}},[_c('header',[_c('Title',{attrs:{"data":{ value: _vm.title }}}),_c('Title',{attrs:{"data":{ value: _vm.post.name, size: 's' }}})],1),_c('Media',{staticClass:"image--desktop",attrs:{"data":{
          type: 'image',
          image: _vm.post.gds_featured_image,
          ratio: false,
          theme: 'single-product',
        }}}),_c('Price',{attrs:{"product":_vm.post,"tax":""}}),(_vm.post.short_description)?_c('Richtext',{attrs:{"data":{
          value: _vm.post.short_description,
        }}}):_vm._e(),_c('div',{staticClass:"shipping-block"},[_c('Label',{attrs:{"data":{ value: _vm.$labels.shipping, size: 'm' },"theme":"grey"}}),_c('Richtext',{attrs:{"data":{ value: _vm.$labels[`shipping_${_vm.post.acf.product_shipping}`] }}}),(_vm.$store.state.wp.site.locale !== 'us')?_c('Cta',{attrs:{"theme":"underline","data":{
            url: '/support/faq#which-countries-do-you-ship-to',
            title: _vm.$labels.countries_cta,
          }}}):_vm._e()],1),_c('Number',{key:_vm.cartLoading,attrs:{"data":{
          id: `number`,
          fn: _vm.numberChanged,
          cartItemKey: _vm.post.id,
          readonly: _vm.cartLoading,
        },"value":_vm.numberToBeAdded},on:{"update:value":function($event){_vm.numberToBeAdded=$event}}}),_c('Cta',{attrs:{"theme":_vm.isOos ? 'button--oos' : 'button',"data":{
          fn: _vm.addToCart,
          title: _vm.isOos ? _vm.$labels.out_of_stock : _vm.$labels.add_to_cart,
        },"disabled":_vm.isOos}})],1),(
        (_vm.post.acf.column_1 && _vm.post.acf.column_1.length > 0) ||
          (_vm.post.acf.column_2 && _vm.post.acf.column_2.length > 0)
      )?_c('Column',{staticClass:"details",attrs:{"offset":{ default: 0, m: 5 },"size":{ default: 12, m: 6 }}},[(_vm.post.acf.column_1 && _vm.post.acf.column_1.length > 0)?_c('div',{staticClass:"column-1"},_vm._l((_vm.post.acf.column_1),function(item,index){return _c('div',{key:index},[_c('Label',{attrs:{"data":{ value: item.label, size: 's' },"theme":"detail"}}),_c('Richtext',{attrs:{"data":{ value: item.text, size: 'label-m' },"theme":"no-space"}})],1)}),0):_vm._e(),(_vm.post.acf.column_2 && _vm.post.acf.column_2.length > 0)?_c('div',{staticClass:"column-2"},_vm._l((_vm.post.acf.column_2),function(item,index){return _c('div',{key:index},[_c('Label',{attrs:{"data":{ value: item.label, size: 's' },"theme":"detail"}}),_c('Richtext',{attrs:{"data":{ value: item.text, size: 'label-m' },"theme":"no-space"}})],1)}),0):_vm._e()]):_vm._e(),(_vm.post.gallery && _vm.post.gallery.length > 0)?_c('Column',{staticClass:"gallery-column",attrs:{"size":"12"}},[_c('Media',{attrs:{"data":{ type: 'gallery', gallery: _vm.post.gallery },"extra-settings":{
          template: 'full',
          wrapAround: true,
          cellAlign: 'center',
        }}})],1):_vm._e(),_c('Column',{attrs:{"size":"12"}},[_c('Related',{attrs:{"product":_vm.post.id,"categories":_vm.post.categories}})],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }