var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'video-player',
    _vm.data.full_height ? 'full-height' : false,
    _vm.data.src ? 'video--cover' : false,
    _vm.data.border ? 'video-bordered' : false,
  ],style:({
    '--width': `${_vm.width}`,
    '--height': `${_vm.height}`,
    '--iframe-height': `${_vm.ratio}vw`,
    '--iframe-min-height': `${100 * (100 / _vm.ratio)}vh`,
  })},[(_vm.data.embed && _vm.data.url)?_c('div',{ref:"player",staticClass:"plyr",attrs:{"data-plyr-provider":_vm.embed.provider,"data-plyr-embed-id":_vm.embed.id,"data-poster":_vm.poster}}):_vm._e(),(!_vm.data.embed && _vm.data.src)?_c('video',{ref:"player",staticClass:"plyr",attrs:{"src":_vm.data.src,"data-poster":_vm.poster,"playsinline":"","autoplay":_vm.data.src,"loop":_vm.data.src},domProps:{"muted":_vm.data.src}}):_vm._e(),(_vm.data.caption)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.data.caption)}}):_vm._e(),(_vm.data.shadow)?_c('div',{staticClass:"shadow"}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }