var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Wrapper',{attrs:{"size":"l","boxed":""}},[_c('Spacer',{class:[
      'reserve',
      _vm.data.layout !== 'default' ? `reserve--${_vm.data.layout}` : false,
    ],attrs:{"all":{ m: 'xl' }}},[_c('Media',{staticClass:"bg",attrs:{"data":_vm.data.media}}),_c('Wrapper',{attrs:{"size":"xs2","margin":false}},[_c('Spacer',{attrs:{"all":{ default: 's', m: 0 }}},[_c('Content',{attrs:{"data":{
            title: _vm.titlePrice,
            rich_text: _vm.data.rich_text,
            boxed: false,
            section_layout: {
              section_wrapper: true,
              section_classes: '',
            },
          }}}),_c('Spacer',{staticClass:"pricing",attrs:{"top":{ default: 's2', m: 'm' }}},[_c('Grid',{attrs:{"col":"12"}},[(_vm.price)?_c('div',[_c('Label',{attrs:{"data":{ value: _vm.data.product.price_label }}}),_c('Richtext',{attrs:{"data":{ value: _vm.price }}})],1):_vm._e(),(_vm.installmentPrice)?_c('div',[_c('Label',{attrs:{"data":{ value: _vm.data.product.installments_label }}}),(_vm.data.modal.modal_type)?_c('Cta',{attrs:{"data":{
                  fn: _vm.openInstallmentModal,
                  title: _vm.installmentPrice,
                  size: 's',
                },"theme":"underline"}}):_vm._e(),_c('p',{directives:[{name:"show",rawName:"v-show",value:(!_vm.data.modal.modal_type),expression:"!data.modal.modal_type"}],staticClass:"affirm-as-low-as typo--s",attrs:{"data-page-type":"category","data-affirm-type":"text","data-learnmore-show":"false","data-amount":_vm.unparseCurrency(_vm.productReserve.price) * 100}},[_vm._v(" "+_vm._s(_vm.installmentPrice)+" ")])],1):_vm._e(),(_vm.shippingPrice)?_c('div',[_c('Label',{attrs:{"data":{ value: _vm.data.product.shipping_label }}}),_c('Richtext',{attrs:{"data":{ value: _vm.shippingPrice }}})],1):_vm._e()])],1),_c('Spacer',{attrs:{"top":{ default: 'm2', m: 'l' }}},[_c('Cta',{attrs:{"data":{
              fn: _vm.addToCart,
              title: _vm.data.cta ? _vm.data.cta.title : _vm.$labels.add_to_cart,
            },"theme":"button"}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }