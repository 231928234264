var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.footer)?_c('footer',{class:[
    _vm.$route.meta.hideHeader ? 'footer-none' : false,
    _vm.$route.meta.customView || _vm.isMobile ? 'footer-visible' : false,
  ]},[_c('div',{staticClass:"layout--dark"},[_c('Wrapper',{staticClass:"typo--label-m",attrs:{"boxed":"","size":"xl"}},[_c('Spacer',{attrs:{"template":"footer"}},[_c('Spacer',{attrs:{"bottom":{ default: 'l', m: 'xxl' }}},[_c('Grid',[_c('Column',{staticClass:"info order-1",attrs:{"size":{ default: 12, m: 3 }}},[_c('Spacer',{attrs:{"top":{ default: 'l', m: 0 }}},[_c('Flex',{attrs:{"align":{ default: 'h-between', m: 'h-left' }}},[_c('inline-svg',{staticClass:"info-logo color--primary",attrs:{"src":require('@/assets/svg/springa-logo.svg')}}),_c('Spacer',{staticClass:"info-text",attrs:{"left":{ default: 'xxs', l: 'm' }}},[_c('Spacer',{attrs:{"bottom":"xxs"}},[_c('Title',{staticClass:"color--primary",attrs:{"data":{ value: _vm.title, size: 'xs' }}})],1),_c('Richtext',{attrs:{"data":{
                        value: _vm.footer.list[0].content,
                        size: 'label-m',
                      }}})],1)],1)],1)],1),_c('Column',{staticClass:"order-2",attrs:{"size":{ default: 12, m: 9 }}},[_c('Grid',{attrs:{"tag":"ul","col":{ default: 6, m: 3 }}},_vm._l((_vm.navigation),function(item){return _c('li',{key:item.id,class:[item.css]},[_c('Spacer',{attrs:{"bottom":"xxs"}},[_c('Title',{staticClass:"color--primary",attrs:{"data":{ value: item.content, size: 'xs' }}})],1),(item.children)?_c('ul',_vm._l((item.children),function(child){return _c('Spacer',{key:child.id,attrs:{"tag":"li","bottom":"xxs"}},[_c('Cta',{attrs:{"data":{
                          url: child.url,
                          title: child.content,
                          target: child.target,
                        }}})],1)}),1):_vm._e()],1)}),0)],1)],1)],1),_c('Spacer',{staticClass:"typo--xs"},[_c('Grid',[_c('Column',{attrs:{"size":{ default: 12, m: 3 }}},[_c('Flex',{attrs:{"align":"v-left"}},_vm._l((_vm.footer.social),function(social){return _c('Spacer',{key:social.label,attrs:{"use-margin":"","right":"xs"}},[_c('a',{staticClass:"social color--primary",attrs:{"href":social.url,"target":"_blank"}},[_c('inline-svg',{attrs:{"src":require(`@/assets/svg/icons/${social.label}.svg`)}})],1)])}),1)],1),_c('Column',{attrs:{"size":{ default: 12, m: 9 }}},[_c('Grid',[_c('Column',{attrs:{"size":{ default: 12, xs: 6, m: 3 }}},[_c('Richtext',{staticClass:"no-link",attrs:{"data":{ value: _vm.footer.credits[0].content, size: 'xs' }}})],1),_c('Column',{attrs:{"size":{ default: 12, xs: 6, m: 3 }}},[_c('Richtext',{staticClass:"no-link",attrs:{"data":{ value: _vm.footer.credits[1].content, size: 'xs' }}})],1),_c('Column',{attrs:{"size":{ default: 12, xs: 6, m: 3 }}},[_c('Richtext',{staticClass:"no-link",attrs:{"data":{ value: _vm.footer.credits[2].content, size: 'xs' }}})],1),_c('Column',{attrs:{"size":{ default: 12, xs: 6, m: 3 }}},[_c('Richtext',{staticClass:"no-link",attrs:{"data":{ value: _vm.footer.credits[3].content, size: 'xs' }}})],1)],1)],1)],1)],1)],1)],1)],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }