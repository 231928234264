var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Spacer',{class:[
    _vm.layout.section_classes && _vm.layout.section_classes !== ''
      ? _vm.layout.section_classes.split(' ')
      : null,
    `layout--${_vm.layout.section_layout}`,
    _vm.layout.section_layout === 'custom' ? _vm.layout.section_layout_custom : null,
    _vm.layout.section_column ? `section--column-${_vm.layout.section_column}` : null,
    _vm.layout.is_hero && _vm.$vnode.key > 0 ? `section--hero--small` : null,
  ],attrs:{"id":_vm.layout.section_id ? _vm.layout.section_id : null,"tag":"section","template":!_vm.layout.is_hero ? _vm.sectionSpacer : undefined}},[_c('Wrapper',{attrs:{"size":_vm.layout.section_wrapper !== 'default'
        ? _vm.layout.section_wrapper === 'no'
          ? false
          : _vm.layout.section_wrapper
        : _vm.layout.section_column === '6'
          ? 'xl'
          : false,"boxed":_vm.layout.section_wrapper_boxed || _vm.layout.section_column === '6'}},[_c('Grid',{attrs:{"col":{ default: 12, m: `${_vm.layout.section_column}` },"extra-class":['section-grid']}},[_vm._t("default")],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }