var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'dropdown',
    'u-hidden--until-m',
    _vm.active ? 'dropdown--active' : false,
  ],style:({
    left: `${_vm.left}px`,
    visibility: _vm.left <= 0 || !_vm.ready ? 'hidden' : 'visible',
  }),on:{"mouseenter":function($event){return _vm.setForce(true)},"mouseleave":function($event){return _vm.setForce(false)}}},[_c('div',{staticClass:"active-currency"},[_c('Icon',{attrs:{"theme":"tool","name":_vm.currentCurrency,"size":"m"}})],1),_vm._l((_vm.otherCurrencies),function(currency){return _c('div',{key:currency.value},[_c('Icon',{attrs:{"theme":"tool","name":currency.name,"size":"m","fn":() => _vm.setCurrency(currency.value)}})],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }