var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.archiveCategory)?_c('div',{staticClass:"related"},[_c('Archive',{attrs:{"data":{
      archive: 'product',
      archive_type: 'archive',
      exclude: _vm.product,
      layout: false,
      posts_per_page: '3',
      product_category: _vm.archiveCategory,
      thumb: 'product',
      title: `Other ${_vm.archiveCategory.name}`,
      unboxed: true,
    }}}),_c('Cta',{attrs:{"data":{
      url: `/shop/${_vm.archiveCategory.slug}`,
      title: `${_vm.$labels.product_discover_cta} ${_vm.archiveCategory.name}`,
    },"theme":"button"}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }