var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'cta',
    _vm.data.size ? `typo--${_vm.data.size}` : null,
    _vm.data.theme || _vm.theme ? `theme--${_vm.data.theme || _vm.theme}` : null,
    _vm.disableHover ? 'cta-no-hover' : null,
    _vm.disabled || _vm.data.disabled ? 'cta-disabled' : null,
    _vm.data.classes,
  ]},[(_vm.data.fn && !_vm.data.url)?[_c('button',{on:{"click":_vm.data.fn}},[_c('Flex',{attrs:{"align":"center"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.data.title)}}),(_vm.data.toggle)?_vm._t(_vm.data.toggle):_vm._e()],2)],1)]:(_vm.hash || _vm.modalYt)?[_c('button',{on:{"click":_vm.goTo}},[_c('Flex',{attrs:{"align":"center"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.data.title)}}),(_vm.data.toggle)?_vm._t(_vm.data.toggle):_vm._e()],2)],1)]:(_vm.data.target === '_blank')?[_c('a',{attrs:{"href":_vm.data.url,"target":_vm.data.target},on:{"click":function($event){_vm.data.fn ? _vm.data.fn() : undefined}}},[_c('Flex',{attrs:{"align":"center"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.data.title)}}),(_vm.data.toggle)?_vm._t(_vm.data.toggle):_vm._e()],2)],1)]:[_c('router-link',{attrs:{"to":_vm.url},nativeOn:{"click":function($event){_vm.data.fn ? _vm.data.fn() : undefined}}},[_c('Flex',{attrs:{"align":"center"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.data.title)}}),(_vm.data.toggle)?_vm._t(_vm.data.toggle):_vm._e()],2)],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }