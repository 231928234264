var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{attrs:{"to":_vm.data.link}},[_c('Hero',{attrs:{"data":{
      slide: [{
        media: {
          type: 'image',
          image: {
            default: _vm.data.gds_featured_image,
            caption: null,
            shadow: true,
          },
        },
        align_media: _vm.data.acf.align_media || 'center',
        title: _vm.data.title.rendered,
      }]
    }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }