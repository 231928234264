<template>
  <div :class="['form-ac']">
    <Wrapper
      size="l"
      boxed
    >
      <Content
        :data="{ title: data.title, rich_text: data.rich_text, boxed: false, margin: false, size_title: 'xl' }"
      />
      <Spacer :top="{ default: 's2', m: 'xxl' }">
        <Wrapper
          size="s"
          :margin="false"
        >
          <div :class="[`_form_${data.ac_form_id}`, 'form-theme--single']" />
        </Wrapper>
      </Spacer>
    </Wrapper>
  </div>
</template>

<script>
import Content from '@/components/blocks/content';
import { mapGetters } from 'vuex';
import { loadScript } from '@/assets/js/utils';

export default {
  name: 'FormAc',
  components: {
    Content,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['isMobile']),
  },
  mounted() {
    loadScript(`https://springa.activehosted.com/f/embed.php?id=${this.data.ac_form_id}`).then(() => {
      const form = this.$el.querySelector('form._form');

      this.$nextTick(() => {
        form.setAttribute('style', 'background: #24282C !important; border: none !important; moz-border-radius: var(--radius) !important; webkit-border-radius: var(--radius) !important; border-radius: var(--radius) !important;');
        form.querySelectorAll('input').forEach((input) => input.setAttribute('style', 'color: #ffffff !important;'));
        form.querySelector('._button-wrapper button').setAttribute('style', 'padding: 10px 24px 14px !important; moz-border-radius: var(--radius) !important; webkit-border-radius: var(--radius) !important; border-radius: var(--radius) !important;');
      });
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/src/03_typography";

.form-ac {
  ::v-deep ._form {
    margin: 0 !important;
    font-family: $sans !important;
    font-weight: 400 !important;
    border-radius: 100px !important;
    max-width: none !important;

    ._form-content {
      display: grid !important;
      row-gap: 8px;

    }

    padding: map-get($spacer, 's') !important;

    @include mq(m) {
      padding: map-get($spacer, 'm') !important;
    }

    * {
      font-family: $sans !important;
      font-weight: 400 !important;
      font-size: 16px !important;
    }

    label {
      font-size: 12px !important;
      text-transform: uppercase;
      color: $grey-l;
    }

    ._form_element {
      margin-bottom: map-get($spacer, 's') !important;
    }

    ._field-wrapper {
      border: 0;

      &>* {
        padding: 14px 14px 14px 0px !important;
        width: 100% !important;
        appearance: none !important;
        display: block !important;
        background: transparent !important;
        border: 0 !important;
        resize: none !important; // Textarea
        font-family: $sans !important;
        color: $white !important;

        @extend %typo--input;
      }

      input[type="text"],
      input[type="tel"],
      input[type="date"],
      option,
      textarea {
        color: $white !important;
      }

      option {
        padding: 4px 0;

        &:checked,
        &:active,
        &:focus {
          background: $grey-xd;
        }
      }
    }

    ._error {
      position: absolute;
      left: 0px !important;
      right: auto !important;
      bottom: -18px !important;
      padding: 0 !important;

      ._error-arrow {
        display: none !important;
      }

      ._error-inner {
        padding: 0 !important;
        background: none !important;
        color: $warning-color !important;
        border-radius: 0 !important;
        font-size: 12px !important;
        text-align: left !important;
      }
    }

    ._button-wrapper {
      margin-top: map-get($spacer, 'm') !important;

      button {
        display: block;
        margin: 0 auto;
        text-align: center;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        white-space: nowrap;
        letter-spacing: 0.02em;
        background: #FFF739;
        color: #15191D;
        white-space: nowrap;
        font-size: 18px !important;
        line-height: 24px !important;

        @include mq($until: m) {
          width: 100% !important;
        }
      }
    }
  }
}
</style>
