var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Wrapper',{class:[`layout--${_vm.data.layout}`],attrs:{"size":_vm.data.wrapper || 'l',"boxed":(typeof _vm.data.boxed !== 'undefined') ? _vm.data.boxed : _vm.data.section_layout ? _vm.data.section_layout.section_wrapper !== 's' : true,"margin":(typeof _vm.data.margin !== 'undefined') ? _vm.data.margin : true}},[_c('Flex',{staticClass:"flex-height",attrs:{"dir":"column","align":"v-between"}},[_c('div',[(_vm.data.label && _vm.data.label !== '')?_c('Label',{attrs:{"data":{
          value: _vm.data.label,
          tag: _vm.data.tag_label,
          size: _vm.data.size_label
        }}}):_vm._e(),(_vm.data.title && _vm.data.title !== '')?_c('Title',{attrs:{"data":{
          value: _vm.data.title,
          tag: _vm.data.tag_title,
          size: _vm.data.size_title
        }}}):_vm._e(),(_vm.data.rich_text && _vm.data.rich_text !== '')?_c('Spacer',{attrs:{"top":_vm.classes.includes('section-community') ? { default: 'm', m: 'xl' } : _vm.data.title !== '' || _vm.data.label !== '' ? 'm' : false}},[_c('Wrapper',{attrs:{"size":_vm.data.section_layout ? _vm.data.section_layout.section_wrapper === 'default' ? 's' : false : 's',"margin":false}},[_c('RichText',{attrs:{"data":{
              value: _vm.data.rich_text,
              size: _vm.data.size_rich_text
            }}})],1)],1):_vm._e(),(_vm.data.specs)?_c('Spacer',{attrs:{"top":"m"}},[_c('Grid',{attrs:{"col":{ default: 12, m: 6 }}},_vm._l((_vm.data.specs),function(spec,index){return _c('div',{key:index,class:!_vm.data.hideBorder ? 'border-line' : false},[_c('Details',{key:index,attrs:{"data":spec}})],1)}),0)],1):_vm._e()],1),(_vm.data.ctas && _vm.data.ctas.length > 0 && !_vm.classes.includes('section-community'))?_c('Spacer',{staticClass:"spacer-cta",attrs:{"top":{ default: 's2', m: 'l' }}},[_c('Flex',{staticClass:"flex-auto",attrs:{"inline":!_vm.isMobile}},_vm._l((_vm.data.ctas),function(item,index){return _c('Spacer',{key:index,attrs:{"right":_vm.data.ctas.length > 1 ? 's' : false,"top":index > 0 ? { default: 'xs', m: 0 } : false}},[(item.cta)?_c('Cta',{attrs:{"data":item.cta,"theme":_vm.data.cta_layout}}):_vm._e()],1)}),1)],1):_vm._e()],1),(_vm.classes.includes('section-community'))?_c(_vm.data.ctas ? _vm.data.ctas[0].cta.target === '_blank' ? 'a' : 'router-link' : 'div',{tag:"component",staticClass:"arrow arrow-community",attrs:{"to":_vm.data.ctas && _vm.data.ctas[0].cta.target !== '_blank' ? _vm.data.ctas[0].cta.url : '/',"href":_vm.data.ctas && _vm.data.ctas[0].cta.target === '_blank' ? _vm.data.ctas[0].cta.url : false,"target":_vm.data.ctas && _vm.data.ctas[0].cta.target === '_blank' ? '_blank' : false}},[_c('inline-svg',{attrs:{"src":require('@/assets/svg/icons/arrow-45.svg')}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }