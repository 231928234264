var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{class:['single', 'layout--default', 'main--no-hero']},[_c('Spacer',{staticClass:"t-center--desktop",attrs:{"top":{ default: 's', m: 'xl' },"bottom":{ default: 'l', m: 'xxxl' }}},[_c('Wrapper',{attrs:{"size":"xl","boxed":""}},[_c('Title',{attrs:{"data":{ value: _vm.post.title.rendered, size: 'xxl' }}})],1)],1),(_vm.post.acf.media)?_c('Spacer',{attrs:{"tag":"section","bottom":{ default: 'm2', m: 'xxl' }}},[_c('Media',{attrs:{"data":_vm.post.acf.media,"extra-settings":{
        cellAlign: 'center',
        contain: false,
        wrapAround: true,
        template: 'project',
        draggable: true,
      }}})],1):_vm._e(),(_vm.post.acf.content)?_c('Spacer',{attrs:{"tag":"section","bottom":{ default: 'l', m: 'xxl' }}},[_c('Content',{attrs:{"data":Object.assign(_vm.post.acf.content, {
          cta_layout: 'button',
          hideBorder: true,
          ctas: _vm.post.acf.content.ctas ? _vm.setFn(_vm.post.acf.content.ctas) : null,
        })}})],1):_vm._e(),(_vm.post.latest)?_c('Spacer',{staticClass:"content",attrs:{"tag":"section","top":{ default: 'l', m: 'xl' },"bottom":{ default: 'xl', m: 'xxxl' }}},[_c('Wrapper',{attrs:{"size":"xl","boxed":""}},[_c('Spacer',{attrs:{"bottom":{ default: 's2', m: 'l' }}},[_c('Title',{attrs:{"data":{ value: 'Latest projects' }}})],1),_c('Grid',{attrs:{"col":{ default: 12, m: 4 }}},_vm._l((_vm.post.latest),function(related){return _c('div',{key:related.id,staticClass:"item"},[_c('Thumb',{attrs:{"data":related}})],1)}),0),_c('Spacer',{staticClass:"t-center",attrs:{"top":{ default: 'm2', m: 'xl' },"bottom":""}},[_c('Cta',{attrs:{"data":{
            title: 'More projects',
            url: '/community/projects/',
          },"theme":"button"}})],1)],1)],1):_vm._e(),(_vm.banner)?_c('Section',{staticClass:"section-community spacer--section-large",attrs:{"layout":{
      section_layout: _vm.banner.layout,
    }}},[_c('Content',{class:['block', 'block--content'],attrs:{"data":Object.assign(_vm.banner, {
          section_layout: {
            section_classes: 'section-community',
            section_wrapper: 'default',
          },
        })}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }