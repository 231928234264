var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{class:[
    'single',
    'layout--default',
    !_vm.post.gds_featured_image || _vm.post.acf.hide_hero ? 'main--no-hero' : null,
  ]},[_c('section',[_c('Hero',{attrs:{"data":{
        slide: [
          {
            media: {
              type: 'image',
              image: {
                default: !_vm.post.acf.hide_hero
                  ? _vm.post.gds_featured_image
                  : false,
                caption: null,
                shadow: true,
              },
            },
            align_media: _vm.post.acf.align_media || 'center',
            title: _vm.post.title.rendered,
          },
        ],
      }}})],1),(_vm.categories || _vm.post.date_format)?_c('Spacer',{staticClass:"t-center meta",attrs:{"tag":"section","y":{ default: 'm2', m: 'xl' }}},[_c('Wrapper',{attrs:{"size":"l","boxed":""}},[_c('Spacer',{attrs:{"bottom":_vm.categories ? 's' : undefined}},[_c('Label',{attrs:{"data":{
            value: _vm.post.date_format,
            size: 's',
          }}})],1),(_vm.categories)?_c('Label',{attrs:{"data":{
          value: _vm.categories,
        }}}):_vm._e()],1)],1):_vm._e(),_c('Spacer',{attrs:{"top":!(!_vm.post.gds_featured_image || _vm.post.acf.hide_hero) &&
        !(_vm.categories || _vm.post.date_format)
        ? { default: 'm2', m: 'xl' }
        : undefined}},[(_vm.post.excerpt && _vm.post.excerpt.rendered !== '')?_c('Spacer',{staticClass:"t-center--desktop",attrs:{"tag":"section","bottom":{ default: 'l', m: 'xxxl' }}},[_c('Wrapper',{attrs:{"size":"l","boxed":""}},[_c('Richtext',{attrs:{"data":{ value: _vm.post.excerpt.rendered, size: 'm' }}})],1)],1):_vm._e(),(_vm.post.content)?_c('Spacer',{staticClass:"content",attrs:{"tag":"section","bottom":"xl"}},[_c('Wrapper',{attrs:{"size":"xl","boxed":""}},[_c('Richtext',{attrs:{"data":{ value: _vm.post.content.rendered, size: 'label-m' }}})],1)],1):_vm._e()],1),(_vm.post.related && _vm.post.related.length > 0)?_c('Spacer',{staticClass:"content",attrs:{"tag":"section","y":{ default: 'l', m: 'xl' }}},[_c('Wrapper',{attrs:{"size":"xl","boxed":""}},[_c('Spacer',{attrs:{"bottom":{ default: 's2', m: 'l' }}},[_c('Title',{attrs:{"data":{ value: 'Related Content' }}})],1),_c('Grid',{attrs:{"col":{ default: 12, m: 4 }}},_vm._l((_vm.post.related),function(related){return _c('div',{key:related.id,staticClass:"item"},[_c('Thumb',{attrs:{"data":related}})],1)}),0),_c('Spacer',{staticClass:"t-center",attrs:{"top":{ default: 'm2', m: 'xl' }}},[_c('Cta',{attrs:{"data":{
            title: 'More news',
            url: {
              name: 'NewsCategory',
              params: {
                cat: _vm.post.gds_taxonomies.categories[0].slug,
              }
            },
          },"theme":"button"}})],1)],1)],1):_vm._e(),(_vm.banner)?_c('Section',{staticClass:"section-community spacer--section-large",attrs:{"layout":{
      section_layout: _vm.banner.layout,
    }}},[_c('Content',{class:['block', 'block--content'],attrs:{"data":Object.assign(_vm.banner, {
          section_layout: {
            section_classes: 'section-community',
            section_wrapper: 'default',
          },
        })}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }