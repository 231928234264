var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"cookie","appear":""}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.cookie && _vm.init),expression:"!cookie && init"}],staticClass:"cookie layout--primary"},[_c('Spacer',{attrs:{"x":{ default: 's', l: 'xl' },"y":{ default: 's', l: 'm' }}},[_c('Icon',{attrs:{"name":"close","size":_vm.isMobile ? 'xs' : 's',"theme":"minimal","fn":_vm.closeCookie}}),_c('Flex',{staticClass:"flex-wrap-cookie"},[_c('Richtext',{attrs:{"data":{
            value: _vm.options.cookies ? _vm.options.cookies.text : '',
          }}}),_c('Spacer',{attrs:{"top":{ default: 's2', l: 0 },"left":{ default: 0, l: 's2' }}},[_c('Flex',{staticClass:"ctas"},[_c('Cta',{attrs:{"data":{
                title: _vm.$labels.accept_cookie,
                fn: _vm.acceptCookie,
                size: 's'
              },"theme":"underline"}})],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }