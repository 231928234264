var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Wrapper',{staticClass:"cover",attrs:{"size":"l","boxed":""}},[(_vm.data.title && _vm.data.title !== '')?_c('Title',{staticClass:"t-center",attrs:{"data":{
      value: _vm.data.title,
      size: 'xl',
    }}}):_vm._e(),_c('div',{staticClass:"card"},[_c('Spacer',{style:({
        backgroundImage: _vm.isMobile
          ? `url(${_vm.data.media.image.mobile.url}`
          : `url(${_vm.data.media.image.default.url}`,
      }),attrs:{"all":{ default: 's', m: 'l' }}},[_c('div',{staticClass:"top"},[(_vm.data.top.label && _vm.data.top.label !== '')?_c('Title',{attrs:{"data":{
            value: _vm.data.top.label,
            tag: 'h3',
          }}}):_vm._e(),(_vm.data.top.text && _vm.data.top.text !== '')?_c('RichText',{attrs:{"data":{
            value: _vm.data.top.text,
          }}}):_vm._e()],1),_c('div',{staticClass:"bottom"},[(_vm.data.bottom.text && _vm.data.bottom.text !== '')?_c('RichText',{attrs:{"data":{
            value: _vm.data.bottom.text,
            size: 'xs',
          }}}):_vm._e(),(_vm.data.bottom.link)?_c('Cta',{attrs:{"theme":"button","data":_vm.data.bottom.link}}):_vm._e()],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }