var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.tag,{tag:"component",class:[
    'flex',
    ..._vm.alignItems,
    ..._vm.direction,
    _vm.inline ? 'flex-inline' : false,
    _vm.noWrap ? 'flex-nowrap' : false
  ]},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }