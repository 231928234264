var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"carousel",staticClass:"box-details"},_vm._l((_vm.post.carousel),function(item){return _c('div',{key:item.id,staticClass:"item"},[(item.acf.cover)?_c('Figure',{attrs:{"data":{ ...item.acf.cover, ratio: false, theme: 'box-detail' }}}):_vm._e(),_c('div',{staticClass:"bottom"},[_c('Title',{attrs:{"data":{
          value: item.title.rendered,
          tag: 'h3',
          size: 'l',
        }}}),(item.acf.description)?_c('Richtext',{attrs:{"data":{ value: item.acf.description, size: 'label-m' }}}):_vm._e(),(item.acf.details && item.acf.details.length > 0)?_c('div',{staticClass:"details"},_vm._l((item.acf.details),function(detail,index){return _c('div',{key:index},[_c('Title',{attrs:{"data":{
              value: detail.title,
              tag: 'h5',
              size: 'xs',
            },"theme":"box-detail"}}),(detail.content)?_c('Richtext',{attrs:{"data":{ value: detail.content, size: 'label-m' },"theme":"no-space"}}):_vm._e()],1)}),0):_vm._e()],1)],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }