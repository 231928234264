var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Wrapper',{attrs:{"size":_vm.data.section_layout &&
      (_vm.data.section_layout.section_wrapper !== 'default' ||
        _vm.data.section_layout.section_wrapper === 'no')
      ? false
      : 'l'}},[(_vm.data.title)?_c('Wrapper',{attrs:{"boxed":!_vm.data.unboxed,"margin":false}},[_c('Spacer',{attrs:{"bottom":{ default: 's2', m: 'l' }}},[_c('Title',{attrs:{"data":{ value: _vm.data.title }}})],1)],1):_vm._e(),(_vm.data.filter)?_c('Spacer',{staticClass:"filters",attrs:{"bottom":"l"}},[_c('Flex',{staticClass:"overflow-scroll",attrs:{"align":"v-baseline","no-wrap":""}},[_c('Spacer',{attrs:{"right":"m2","left":{ default: 's', m: 'm2' }}},[_c('Label',{attrs:{"data":{ value: 'Filter:', size: 'm' }}})],1),(_vm.filters)?_c('Grid',{attrs:{"col":"auto"}},_vm._l((_vm.filters),function(filter){return _c('Cta',{key:filter.id,class:[
            'filter',
            _vm.filterActive && _vm.filterActive !== filter.id
              ? 'filter-not-active'
              : false,
          ],attrs:{"data":{
            title: filter.name,
            fn: () => _vm.changeFilter(filter.id),
          },"theme":"button","disable-hover":_vm.filterActive === filter.id}})}),1):_vm._e()],1)],1):_vm._e(),_c('Wrapper',{attrs:{"boxed":!_vm.classes.includes('archive-stories') && !_vm.data.unboxed,"margin":false}},[(_vm.items.length > 0)?_c('Grid',{attrs:{"col":_vm.gridCol,"template":_vm.gridTemplate}},_vm._l((_vm.items),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c(_vm.thumb(_vm.data.thumb),{tag:"component",attrs:{"data":{ ...item, carousel: _vm.data.thumb === 'box' ? _vm.items : false }}})],1)}),0):_c('div',[_c('Skeleton',{attrs:{"items":_vm.skeletonItems,"cols":_vm.gridCol,"thumb-component":_vm.data.thumb}})],1),(_vm.showMore)?_c('Spacer',{staticClass:"t-center",attrs:{"top":{ default: 'm2', m: 'xl' }}},[_c('Cta',{attrs:{"data":{
          title: 'Show more',
          fn: _vm.requestOffset,
        },"theme":"button","disabled":_vm.showMoreAvoid}})],1):_vm._e(),(_vm.data.cta)?_c('Spacer',{staticClass:"t-center",attrs:{"top":{ default: 'm2', m: 'xl' }}},[_c('Cta',{attrs:{"data":_vm.data.cta,"theme":"button"}})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }