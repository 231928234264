var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.data[_vm.data.type])?_c(_vm.components[_vm.data.type],{tag:"component",attrs:{"extra-settings":_vm.extraSettings,"data":Object.assign(_vm.data[_vm.data.type], {
      align: _vm.data.align || 'center',
      border: _vm.data.border,
      disableCarousel: _vm.data.disable_carousel,
      full_height: _vm.data.full_height,
      lazyload: _vm.data.lazyload,
      object_fit: _vm.data.object_fit ? 'cover' : 'contain',
      ratio: _vm.data.ratio,
      size: _vm.data.size,
      theme: _vm.data.theme || '',
    })}}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }