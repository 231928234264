var render = function render(){var _vm=this,_c=_vm._self._c;return _c('figure',{class:[
    'picture',
    _vm.data.full_height ? 'full-height' : false,
    !_vm.data.ratio ? 'no-ratio' : false,
    _vm.data.object_fit ? `picture-${_vm.data.object_fit}` : 'picture-cover',
    _vm.data.border ? 'picture-bordered' : false,
    _vm.data.align ? `align--${_vm.data.align}` : false,
    _vm.data.marquee ? `is-marquee` : false,
    _vm.data.theme ? `picture--${_vm.data.theme}` : false,
  ],attrs:{"data-lazy":_vm.data.lazyload ? 'false' : null}},[(_vm.imageType !== 'svg')?_c('picture',{style:({
      paddingBottom: !_vm.data.full_height && _vm.data.ratio ? `${_vm.ratio}%` : false,
    })},[_c('img',{ref:"image",staticClass:"image",attrs:{"data-src":_vm.data.lazyload || _vm.skipLoad ? _vm.src : null,"src":!_vm.data.lazyload && !_vm.skipLoad ? _vm.src : '',"alt":_vm.defaultImage.alt}}),(_vm.data.lazyload)?_c('div',{staticClass:"placeholder"}):_vm._e()]):(_vm.imageType === 'inline-svg')?_c('div',{staticClass:"svg",domProps:{"innerHTML":_vm._s(_vm.data.svg)}}):(_vm.imageType === 'svg')?_c('div',[_c('img',{ref:"image",staticClass:"svg",attrs:{"src":_vm.defaultImage.url,"alt":_vm.defaultImage.alt}})]):_vm._e(),(_vm.data.caption !== '')?_c('Spacer',{attrs:{"tag":"figcaption","all":{ default: 's', m: 'm' }},domProps:{"innerHTML":_vm._s(_vm.data.caption)}}):_vm._e(),(_vm.data.shadow)?_c('div',{staticClass:"shadow"}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }