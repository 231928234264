var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"fade","appear":""}},[_c('Flex',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],class:[
      'popup',
    ],nativeOn:{"click":function($event){return _vm.close.apply(null, arguments)}}},[_c('Wrapper',{class:[
        'popup-wrapper',
        'layout--white radius'
      ],attrs:{"size":'m'},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_c('Icon',{staticClass:"close",attrs:{"name":"close","fn":_vm.close,"theme":"default","size":"m"}}),(_vm.popup.image)?_c('Figure',{staticClass:"media",attrs:{"data":Object.assign(_vm.popup.image, { caption: null, full_height: false, lazyload: true, ratio: false, object_fit: 'cover' })}}):_vm._e(),_c('Spacer',{attrs:{"all":{ default: 'm', m: 'm' }}},[_c('Content',{attrs:{"data":{
            title: _vm.popup.title,
            rich_text: _vm.popup.text,
            ctas: _vm.popup.link ? [{ cta: { ..._vm.popup.link, fn: _vm.close } }] : [],
            cta_layout: 'button',
            boxed: false,
            size_title: 'm',
            size_rich_text: 'label-m'
          }}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }