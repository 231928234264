var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Wrapper',{attrs:{"size":_vm.data.section_layout ? _vm.data.section_layout.section_wrapper === 'default' ? 'l' : false : 'l',"boxed":(typeof _vm.data.boxed !== 'undefined') ? _vm.data.boxed : true}},[_c('Spacer',{attrs:{"bottom":{ default: 's2', m: 'xl'}}},[(_vm.data.title)?_c('Title',{attrs:{"data":{ value: _vm.data.title, size: _vm.data.thumb === 'story' || _vm.data.thumb === 'team' || _vm.data.thumb === 'box' ? 'xl' : null }}}):_vm._e()],1),_c('Grid',{attrs:{"col":_vm.gridCol,"template":_vm.gridTemplate}},[_vm._l((_vm.items),function(item,index){return _c('div',{key:index,staticClass:"item"},[(_vm.data.thumb === 'default')?[(_vm.checkContent(item))?_c('Content',{attrs:{"data":_vm.setContent(item)}}):_vm._e(),(_vm.checkMedia(item))?_c('Media',{attrs:{"data":_vm.setMedia(item)}}):_vm._e()]:_c(_vm.thumb(_vm.data.thumb),{tag:"component",attrs:{"data":{
          content: _vm.checkContent(item) ? _vm.setContent(item) : null,
          media: _vm.setMedia(item) ? _vm.setMedia(item) : null,
        }}})],2)}),(_vm.showMore && _vm.isMobile)?_c('Spacer',{staticClass:"t-center",attrs:{"top":{default: 'm' }}},[_c('Cta',{attrs:{"data":{
          title: 'Show more',
          fn: _vm.showAll,
        },"theme":"button"}})],1):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }