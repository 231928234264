var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Wrapper',{attrs:{"size":_vm.data.size ? _vm.data.size : 'l',"boxed":_vm.data.boxed !== undefined ? _vm.data.boxed : true}},[_c('Grid',{staticClass:"accordion-wrapper",attrs:{"col":"12"}},_vm._l((_vm.data.items),function(item,index){return _c('AccordionWrapper',{key:index,attrs:{"is-active":index === _vm.activeItem}},[_c('AccordionItem',{attrs:{"id":_vm.kebabCase(item.title_trigger),"theme":{layout: _vm.data.itemLayout ? _vm.data.itemLayout : 'dark' },"base-hash":_vm.kebabCase(_vm.data.title)}},[_c('template',{slot:"accordion-trigger"},[_c('Cta',{attrs:{"data":{ title: item.title_trigger, size: _vm.data.triggerSize ? _vm.data.triggerSize : 's', theme: _vm.data.triggerTheme ? _vm.data.triggerTheme : 'default', fn: () => {}}}})],1),_c('template',{slot:"accordion-content"},[_c('Content',{attrs:{"data":{
              title: item.title,
              rich_text: item.rich_text,
              size_rich_text: 'label-m',
              ctas: item.cta ? [{cta: item.cta }] : [],
              cta_layout: 'underline',
              boxed: false
            }}})],1)],2)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }